<template>
  <div>
    <div class="d-flex justify-space-between align-start">
      <!-- left -->
      <div class="d-flex flex-wrap" style="gap:0px 20px">
        <!-- search -->
        <div>
          <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          placeholder="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
          height="40px"
          ></v-text-field>
        </div>

        <!-- filter by date range -->
        <div>
          <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="auto"
          offset-y
          nudge-top="20"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-text-field
              :value="dateRangeText"
              placeholder="Filter by Date Range"
              v-bind="attrs"
              append-icon="mdi-calendar"
              v-on="on"
              outlined
              dense
              clearable
              readonly
              height="40px"
              @click:clear="dateRange = []"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="dateRange"
            no-title
            range
            scrollable
            :max="maxDate"
            @input="onDateSelected"
          >
          </v-date-picker>
        </v-menu>
       </div>

        <!-- Filter by assigned sales -->
        <div v-if="[$keys.SALES_HEAD].includes(user.user_type)">
          <v-autocomplete
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon="mdi-chevron-down"
            v-model="executive"
            :items="executive_list"
            item-text="name"
            item-value="id"
            outlined
            dense
            clearable
            height="40px"
            placeholder="Filter by Sales"
          >
          </v-autocomplete>
        </div>

        <!-- Filter by assigned operations -->
        <div v-if="[$keys.OPERATIONS_HEAD, $keys.ACCOUNTS].includes(user.user_type)">
          <v-autocomplete
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon="mdi-chevron-down"
            v-model="executive"
            :items="executive_list"
            item-text="name"
            item-value="id"
            outlined
            dense
            clearable
            height="40px"
            placeholder="Filter by Operations"
           
          >
          </v-autocomplete>
        </div>

        <!-- Filter by university -->
        <div v-if="
              [
                $keys.SUPER_ADMIN,
                $keys.SALES_HEAD,
                $keys.SALES_EXECUTIVE,
                $keys.OPERATIONS_HEAD,
                $keys.OPERATIONS_EXECUTIVE,
              ].includes(user.user_type)
            ">
          <v-autocomplete
            auto-select-first
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="university"
            :items="university_list"
            :search-input.sync="university_search"
            :loading="university_list_loading"
            item-text="name"
            item-value="id"
            outlined
            dense
            clearable
            no-filter
            height="40px"
            placeholder="Filter by University"
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span v-if="university_list_loading">Searching...</span>
                <span v-else> No data available </span>
              </div>
            </template>
          </v-autocomplete>
        </div>
       
        <!-- Filter by eca -->
         <div v-if="
              [
                $keys.SUPER_ADMIN,
                $keys.SALES_HEAD,
                $keys.SALES_EXECUTIVE,
                $keys.OPERATIONS_HEAD,
                $keys.OPERATIONS_EXECUTIVE,
              ].includes(user.user_type)
            ">
          <v-autocomplete
            auto-select-first
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="eca"
            :items="eca_list"
            :search-input.sync="eca_search"
            :loading="eca_list_loading"
            item-text="name"
            item-value="id"
            outlined
            dense
            clearable
            no-filter
            height="40px"
            placeholder="Filter by ECA Body"
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span v-if="eca_list_loading">Searching...</span>
                <span v-else> No data available </span>
              </div>
            </template>
          </v-autocomplete>
        </div> 

        <!-- Filter by Status -->
         <div v-if="
              [
                $keys.SUPER_ADMIN,
                $keys.SALES_HEAD,
                $keys.SALES_EXECUTIVE,
                $keys.OPERATIONS_HEAD,
                $keys.OPERATIONS_EXECUTIVE,
              ].includes(user.user_type)
            ">
          <v-autocomplete
            auto-select-first
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="status"
            :items="status_list"
            :loading="status_list_loading"
            item-text="label"
            item-value="key"
            outlined
            dense
            clearable
            no-filter
            height="40px"
            placeholder="Filter by Status"
            v-if="
              [
                $keys.SUPER_ADMIN,
                $keys.OPERATIONS_HEAD,
                $keys.OPERATIONS_EXECUTIVE,
              ].includes(user.user_type)
              "
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span> No data available </span>
              </div>
            </template>
          </v-autocomplete>
        </div> 
        <div 
        v-if="
              [
                $keys.SUPER_ADMIN,
              ].includes(user.user_type)
            "
        >
          <v-autocomplete
            auto-select-first
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="roleSelected"
            :items="rolesList"
            :loading="roleSelectedLoading"
            item-text="label"
            item-value="value"
            outlined
            dense
            clearable
            no-filter
            height="40px"
            placeholder="Select Role "
            v-if="
              [
                $keys.SUPER_ADMIN,
              ].includes(user.user_type)
              "
          >
            <template v-slot:no-data>
              <div class="mx-3 my-1 text-caption">
                <span> No data available </span>
              </div>
            </template>
          </v-autocomplete>
        </div>

      </div>

      <!-- right -->
      <div>
        <!-- add case btn -->
        <v-btn
          @click="caseAdd"
          class="text-capitalize px-5"
          color="primary"
          height="40px"
          outlined
          v-if="
            [
              $keys.SALES_EXECUTIVE,
              $keys.SALES_HEAD,
              $keys.UNIVERSITY,
              $keys.SUPER_ADMIN,
            ].includes(user.user_type)
          "
        >
          <v-icon size="16" left>mdi-plus</v-icon>Add Case
        </v-btn>
      </div>
    </div>

    <!-- tabs -->
    <v-sheet elevation="3" class="rounded-b-0">
      <v-tabs
        height="125px"
        next-icon="mdi-chevron-right-circle"
        prev-icon="mdi-chevron-left-circle"
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          v-for="(item, index) in tabs"
          :key="index + item.key"
          class="tab-width"
        >
          <div>
            <div class="tab-total">
              {{ item.value }}
            </div>
            <div class="tab-name">
              {{ item.label }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </v-sheet>

    <v-divider />

    <!-- sales table -->
    <admin-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.SUPER_ADMIN].includes(user.user_type)"
    />

    <!-- sales table -->
    <sales-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.SALES_HEAD, $keys.SALES_EXECUTIVE].includes(user.user_type)"
    />

    <!-- operations table -->
    <operation-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="
        [$keys.OPERATIONS_HEAD, $keys.OPERATIONS_EXECUTIVE].includes(
          user.user_type
        )
      "
    />

    <!-- accounts table -->
    <accounts-data-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.ACCOUNTS].includes(user.user_type)"
    />

    <!-- university table -->
    <university-data-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.UNIVERSITY].includes(user.user_type)"
    />

    <!-- eca table -->
    <eca-data-table
      ref="dataTable"
      @reloadData="getCountList"
      v-if="[$keys.ECA_BODY].includes(user.user_type)"
    />

    <!-- main -->
    <router-view />

    <!-- dialogs -->
    <AddCaseDialog @submitted="reloadData" />
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import {
  salesTabs,
  operationsTabs,
  accountsTabs,
  universityTabs,
  ecaTabs,
  adminTabs,
} from "@/tabs/caseManagement";

import adminTable from "./adminTable";
import salesTable from "./salesTable.vue";
import OperationTable from "./operationTable.vue";

import AccountsDataTable from "@/components/CaseManagement/AccountsDataTable";
import UniversityDataTable from "@/components/CaseManagement/University/DataTable";
import EcaDataTable from "@/components/CaseManagement/EcaDataTable";
import AddCaseDialog from "@/components/CaseManagement/University/AddCaseDialog";

export default {
  components: {
    adminTable,
    salesTable,
    OperationTable,
    AccountsDataTable,
    UniversityDataTable,
    EcaDataTable,
    AddCaseDialog,
  },
  data() {
    return {
      dateMenu: false,
      executive_list: [],

      university_list: [],
      university_search: "",
      university_list_loading: false,
      
      eca_list: [],
      eca_search: "",
      eca_list_loading: false,
     
      status_list: [],
      status_list_loading: false,
      rolesList : [
        {
          label : 'Super Admin',
          value:'super_admin'
        },
        {
          label : 'Sales',
          value:'sales_head'
        },
        {
          label : 'Operations',
          value:'operations_head'
        },
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      filters: "caseManagement/filters",
      getSearch: "caseManagement/search",
      getActiveTab: "caseManagement/activeTab",
      serviceTypes: "getServiceTypes",
      serviceTypeSwitch: "topNav/serviceTypeSwitch",
    }),

    activeTab: {
      get() {
        return this.getActiveTab.index;
      },
      set(value) {
        this.setActiveTab({ index: value, key: this.tabs[value].key });
      },
    },

    selectedServiceType() {
      if (this.serviceTypes.length) {
        return this.serviceTypes[this.serviceTypeSwitch.selected].key;
      }
      return this.$keys.EDUCATIONAL_DOCUMENTATION;
    },

    tabs() {
      let _tabs = [];
      switch (this.user.user_type) {
        case this.$keys.SALES_HEAD:
        case this.$keys.SALES_EXECUTIVE:
          _tabs = salesTabs;
          break;

        case this.$keys.OPERATIONS_HEAD:
        case this.$keys.OPERATIONS_EXECUTIVE: {
          _tabs = operationsTabs[this.selectedServiceType];
          break;
        }

        case this.$keys.ACCOUNTS:
          _tabs = accountsTabs;
          break;

        case this.$keys.UNIVERSITY:
          _tabs = universityTabs;
          break;

        case this.$keys.ECA_BODY:
          _tabs = ecaTabs;
          break;

        case this.$keys.SUPER_ADMIN:
          if(this.roleSelected === this.$keys.SALES_HEAD){
            _tabs = salesTabs
            break
          }
          else if(this.roleSelected === this.$keys.OPERATIONS_HEAD){
            _tabs = operationsTabs[this.selectedServiceType];
            break
          }
          else{
            _tabs = adminTabs[this.selectedServiceType];
            break
          }

        default:
          _tabs = [];
      }

      return _.filter(_tabs, (tab) => {
        if (
          !(
            ["open", "assigned_to_sales", "assigned_to_operations"].includes(
              tab.key
            ) &&
            ["sales_executive", "operations_executive"].includes(
              this.user.user_type
            )
          )
        )
          return tab;
      });
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    dateRangeText() {
      const _filter_by_date = this.dateRange.map((item) =>
        this.$moment(item).format("DD-MM-YYYY")
      );
      return _filter_by_date.join(" ~ ");
    },

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },

    dateRange: {
      get() {
        return this.filters.date;
      },
      set(value) {
        this.setFilters({ date: value });
      },
    },

    executive: {
      get() {
        return this.filters.executive;
      },
      set(value) {
        this.setFilters({ executive: value });
      },
    },

    university: {
      get() {
        return this.filters.university;
      },
      set(value) {
        this.setFilters({ university: value });
      },
    },
    
    eca: {
      get() {
        return this.filters.eca;
      },
      set(value) {
        this.setFilters({ eca: value });
      },
    },
    status: {
      get() {
        return this.filters.status;
      },
      set(value) {
        this.setFilters({ status: value });
      },
    },
    roleSelected: {
      get() {
        return this.filters.role;
      },
      set(value) {
        this.setFilters({ role: value });
      },
    },
  },

  watch: {
    selectedServiceType() {
      this.activeTab = 0;
    },

    university_search: _.debounce(function (query) {
      if (this.university_list_loading) return;

      if (query && !this.university) {
        this.getUniversityList();
      }
    }, 1000),
    
    eca_search: _.debounce(function (query) {
      if (this.eca_list_loading) return;

      if (query && !this.eca) {
        this.getEcaList();
      }
    }, 1000),
  },

  created() {
    if (this.tabs) {
      this.setActiveTab({
        index: this.activeTab,
        key: this.tabs[this.activeTab].key,
      });
    }

    this.getCountList();
  },

  mounted() {
    this.getExecutiveList();
    this.getStatusList();

    if (
      [
        this.$keys.OPERATIONS_HEAD,
        this.$keys.OPERATIONS_EXECUTIVE,
        this.$keys.SUPER_ADMIN,
      ].includes(this.user.user_type)
    ) {
      this.toggleServiceTypeSwitch({ show: true });
    }

    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.toggleServiceTypeSwitch({ show: false });
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      setSearch: "caseManagement/setSearch",
      setFilters: "caseManagement/setFilters",
      setActiveTab: "caseManagement/setActiveTab",
      toggleAddCaseDialog: "university/toggleAddCaseDialog",
      toggleServiceTypeSwitch: "topNav/toggleServiceTypeSwitch",
    }),

    navClickHandler(navKey) {
      if (navKey === "case_management") {
        this.reloadData();
        this.$refs.dataTable.updateRoute();
      }
    },

    reloadData() {
      this.getCountList();
      this.$refs.dataTable.getCaseList();
    },

    onDateSelected() {
      if (this.dateRange.length === 2) this.dateMenu = false;
    },

    caseAdd() {
      if ([this.$keys.UNIVERSITY].includes(this.user.user_type)) {
        this.toggleAddCaseDialog({ show: true });
      } else {
        this.$router.push({ name: "caseAdd" });
      }
    },

    getCountList() {
      const onSuccess = (res) => {
        this.tabs.forEach((tab) => {
          tab.value = res.data.data[tab.key];
        });
        this.$forceCompute("tabs");
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        search: this.search,
        executive_id: this.executive,
        university_id: this.university,
        eca_body_id: this.eca,
        status: this.status,
        role_type:this.roleSelected
      };

      if (this.dateRange) {
        if (this.dateRange.length === 2) {
          params["start_date"] = this.dateRange[0];
          params["end_date"] = this.dateRange[1];
        } else {
          params["date"] = this.dateRange[0];
        }
      }

      if (
        [
          this.$keys.OPERATIONS_HEAD,
          this.$keys.OPERATIONS_EXECUTIVE,
          this.$keys.SUPER_ADMIN,
        ].includes(this.user.user_type)
      ) {
        params["service_type"] = this.selectedServiceType;
      }

      return this.$request(this.$keys.GET, this.$urls.case.count, {
        params,
        onSuccess,
      });
    },

    getExecutiveList() {
      const params = {
        apply_pagination: false,
      };

      if ([this.$keys.SALES_HEAD].includes(this.user.user_type)) {
        params["list_type"] = "assign_sales";
      }

      if (
        [this.$keys.OPERATIONS_HEAD, this.$keys.ACCOUNTS].includes(
          this.user.user_type
        )
      ) {
        params["list_type"] = "assign_operations";
      }

      const successHandler = (res) => {
        this.executive_list = res.data.data;
      };

      this.$request(this.$keys.GET, this.$urls.team.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getUniversityList() {
      this.university_list_loading = true;

      const onSuccess = (res) => {
        this.university_list = res.data.data;
      };

      const onFinally = () => {
        this.university_list_loading = false;
      };

      const params = {
        search: this.university_search,
      };

      return this.$request(this.$keys.GET, this.$urls.university.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
    
    getEcaList() {
      this.eca_list_loading = true;

      const onSuccess = (res) => {
        this.eca_list = res.data.data;
      };

      const onFinally = () => {
        this.eca_list_loading = false;
      };

      const params = {
        search: this.eca_search,
      };

      return this.$request(this.$keys.GET, this.$urls.eca.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
    getStatusList() {
      this.status_list_loading = true;

      const onSuccess = (res) => {
        this.status_list = res.data;
      };

      const onFinally = () => {
        this.status_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.case.status, {
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>
<style scoped>
.tab-total {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.tab-name {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
</style>
